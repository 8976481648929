import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { FundamentalData } from "react-ts-tradingview-widgets";
import { CompanyProfile } from "react-ts-tradingview-widgets";

export function AssetInfo() {
  const { symbol } = useParams();
  const theme = useTheme();

  return (
    <Box>
      <Grid container columns={16} spacing={2}
        sx={{ pl: 2, mt: 4 }}>
        {/* Left Pannel */}
        <Grid item xs={6}>
          <Box
            sx={{ mb: 4 }}>
            <FundamentalData symbol={symbol} colorTheme={theme.palette.mode} height={350} width="100%"></FundamentalData>
          </Box>
          <Box>
            <CompanyProfile symbol={symbol} colorTheme={theme.palette.mode} height={350} width="100%"></CompanyProfile>
          </Box>
        </Grid>
        {/* Right Pannel */}
        <Grid item xs={10} sx={{
          pr: 2
        }}>
          <AdvancedRealTimeChart symbol={symbol} theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
        </Grid>
      </Grid>


    </Box>
  );
}
