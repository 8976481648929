import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { MarketData } from "react-ts-tradingview-widgets";

export function Stocks() {
  const theme = useTheme();

  return (
    <div>

      <Grid container columns={16}
        sx={{ mt: 10, mb: 2, px: 2 }}>
        {/* Left Pannel */}
        <Grid item xs={8}>
          <Box sx={{ height: 400 }}>A</Box>
        </Grid>
        {/* Right Pannel */}
        <Grid item xs={8} sx={{
          pr: 1,
          mb: 2
        }}>
          <AdvancedRealTimeChart symbol="NASDAQ:QQQ" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
        </Grid>
      </Grid>
      <Box height="500px"
        sx={{ px: 2 }}>

        <MarketData colorTheme={theme.palette.mode} height={400} width={window.innerWidth - 60} ></MarketData>
      </Box>
    </div>);
}