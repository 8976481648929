import Button from "@mui/material/Button";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./auth/AuthProvider";
import { Layout } from "./Component/Layout";
import { store } from "./store/store";
import { AssetInfo } from "./views/AssetInfo";
import { Details } from "./views/Details";
import { EconomicData } from "./views/EconomicData";
import { Forex } from "./views/Forex";
import { Home } from "./views/Home";
import { IndexManagement } from "./views/IndexManagement";
import { Landing } from "./views/Landing";
import { Prototype } from "./views/Prototype";
import { ReportIssue } from "./views/ReportIssue";
import { Stocks } from "./views/Stocks";
import { Support } from "./views/Support";

import { MintedIndexes } from "./views/MintedIndexes";
import { Predictions } from "./views/Predictions";
// const ENDPOINT = "http://127.0.0.1:3000";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    bg: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    bg?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    bg: true;
  }
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function MyApp() {
  // const [response, setResponse] = useState("");
  // const [responseBtcTicker, setResponseBtcTicker] = useState("");

  // useEffect(() => {
  //     const socket = socketIOClient(ENDPOINT);
  //     socket.on("FromAPI", data => {
  //         setResponse(data);
  //     });
  //     socket.on("msgBtcTicker", data => {
  //         setResponseBtcTicker(data);
  //     });

  // }, []);

  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <Router>
      {/* <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    borderRadius: 1,
                }}
            >
                {theme.palette.mode} mode
                <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
            </Box> */}
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details" element={<Details />} />
          <Route path="/prototype" element={<Prototype />} />
          <Route path="/crypto" element={<Landing />} />
          <Route path="/forex" element={<Forex />} />
          <Route path="/economic-data" element={<EconomicData />} />
          <Route path="/stocks" element={<Stocks />} />
          <Route path="/asset/:symbol" element={<AssetInfo />} />
          <Route path="/support" element={<Support />} />
          <Route path="/index-management" element={<IndexManagement />} />
          <Route path="/predictions" element={<Predictions />} />
          <Route path="/report-issue" element={<ReportIssue />} />
          <Route path="/minted-indexes" element={<MintedIndexes />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export function App() {
  const [mode, setMode] = React.useState<"light" | "dark">("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            light: "#70FFDF",
            main: "#00FFC6",
            dark: "#008C6D",
            contrastText: "#00FFC6",
          },
          bg: {
            ...(mode === "light"
              ? {
                  main: "#3b668e",
                  dark: "#3b668e",
                  light: "#fff",
                  contrastText: "#D5E0E5",
                }
              : {
                  main: "#00061A",
                  dark: "#00061A",
                  light: "#00061A",
                  contrastText: "#D5E0E5",
                }),
          },
          neutral: {
            light: "#313233",
            main: "#080a0c",
            dark: "#000000",
            contrastText: "#D5E0E5",
          },
          text: {
            ...(mode === "light"
              ? {
                  primary: "#D5E0E5",
                  secondary: "#00FFC6",
                }
              : {
                  primary: "#D5E0E5",
                  secondary: "#00FFC6",
                }),
          },
        },
        typography: {
          button: {
            textTransform: "inherit",
          },
        },
      }),
    [mode]
  );

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <MyApp />
            <ToastContainer />
          </AuthProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}
