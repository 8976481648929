const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function computePercentChange(val1: number, val2: number) {
  return ((val2 - val1) * 100 / val1).toFixed(2);
}

export function computeDateFormatted(date: Date) {
  var month: string = date.toLocaleString('default', { month: 'short' });
  var day: string = date.getDate().toString();
  var year: string = date.getUTCFullYear().toString();
  return month + " " + day + ", " + year;

}

export function computeDateDifference(date: Date) {
  var now: Date = new Date();
  const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utc2 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const daysPassed = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  if (daysPassed < 1) {
    return "Today";
  } else if (daysPassed < 7) {
    return daysPassed.toString() + " days ago";
  } else if (daysPassed < 30) {
    return Math.floor(daysPassed/7).toString() + " week(s) ago";
  } else if (daysPassed < 356) {
    return Math.floor(daysPassed/30).toString() + " month(s) ago";
  } else {
    return Math.floor(daysPassed/365).toString() + " year(s) ago";
  }
}