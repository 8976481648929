import { Box } from "@mui/material";
import { withAuth } from "../auth/withAuth";
import { PredictionForm } from "../Component/PredictionForm/PredictionForm";

export const Predictions = withAuth(() => {
  return (
    <div>
      <Box sx={{ p: 8, margin: "auto" }}>
        <PredictionForm />
      </Box>
    </div>
  );
});
