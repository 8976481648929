import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../store/authSlice";
import { RootState } from "../store/store";

const apiUrl = process.env.REACT_APP_API_URL;

interface TAuthContext {
  isAuthenticated: boolean;
  token: string | null;
  sendCode(email: string): Promise<any>;
  verifyCode({ email, code }: { email: string; code: string }): Promise<any>;
}

export const AuthContext = React.createContext<TAuthContext>({
  isAuthenticated: false,
  token: null,
  sendCode: async (email: string) => {},
  verifyCode: async ({ email, code }: { email: string; code: string }) => {},
});

export const AuthProvider = ({ children }: any) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();
  const isAuthenticated = !!token;

  const sendCode = async (email: string) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    return fetch(`${apiUrl}/auth/send-magic-link`, requestOptions).then(
      (response) => response.json()
    );
  };

  const verifyCode = async ({
    email,
    code,
  }: {
    email: string;
    code: string;
  }) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("code", code);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `${apiUrl}/auth/verify-magic-link?email=${email}&code=${code}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(setToken(response.accessToken));
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token,
        sendCode,
        verifyCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
