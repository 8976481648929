import { Box, Link } from "@mui/material";

export const MintedIndexes = () => (
  <div>
    <Box sx={{ p: 8, margin: "auto" }}>
      <Link
        fontSize={"75px"}
        href="https://www.tokensets.com/v2/set/polygon/0xe1cb878293409a9b19de2502df1b1e48609c7cdc"
      >
        RAIR-100
      </Link>
    </Box>
  </div>
);
