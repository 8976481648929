import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import Sparkline from "../Component/Sparkline/Sparkline";
import { useTheme } from '@mui/material/styles';

import { Screener, CopyrightStyles } from "react-ts-tradingview-widgets";

import { MiniChart } from "react-ts-tradingview-widgets";
import { ForexHeatMap } from "react-ts-tradingview-widgets";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: "relative",
    // width: 150,
    // height: 100,
    // top: "-45px"
  }
};

/*
const mainBoxCharts = [
  { name: "EUR/USD" },
  { name: "GBP/USD" },
  { name: "USD/JPY" },
  { name: "USD/CAD" },
  { name: "USD/CHF" },
  { name: "AUD/USD" },
  { name: "NZD/USD" },
  { name: "EUR/GBP" },
  { name: "GBP/JPY" }
];
*/

const mainBoxCharts = [
  { name: "FX:EURUSD" },
  { name: "FX:GBPUSD" },
  { name: "FX:USDJPY" },
  { name: "FX:USDCAD" },
  { name: "FX:USDCHF" },
  { name: "FX:AUDUSD" },
  { name: "FX:NZDUSD" },
  { name: "FX:EURGBP" },
  { name: "FX:GBPJPY" }
];

const currencies: any[] = ["EUR", "USD", "GBP", "CAD", "JPY", "AUD", "CHF", "NZD"];

export function Forex() {
  const theme = useTheme();

  return (
    <Box sx={{
      // backgroundImage: "url(" + `${process.env.PUBLIC_URL}/bg.jpeg` + ")",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat'
    }}>
      <Grid container columns={16}
        sx={{ pt: 8, px: 2 }}>
        {/* Left Pannel */}
        <Grid item xs={8}>
          {/* <Box sx={{ height: 400 }}>A</Box> */}
          <Screener colorTheme={theme.palette.mode} width="100%" ></Screener>
        </Grid>
        {/* Right Pannel */}
        <Grid item xs={8} sx={{
          pr: 1
        }}>
          <Grid container spacing={0.5} sx={{
            justifyContent: 'flex-end'
          }}>
            {mainBoxCharts.map((child, index) => {
              return (
                <Grid item xs={5}>
                  <MiniChart symbol={child.name} colorTheme={theme.palette.mode} autosize ></MiniChart>
                  {/* <AdvancedRealTimeChart symbol={child.name} theme={theme.palette.mode} autosize ></AdvancedRealTimeChart> */}
                </Grid>
                // <Grid item xs={5}>
                //   <Box
                //     sx={{
                //       height: 150,
                //       color: 'white',
                //       backgroundColor: '#2b382a',
                //     }}>
                //     <Grid container>
                //       <Grid item xs={6}>
                //         <Box
                //           sx={{
                //             px: 1
                //           }}>
                //           <Typography sx={{ pt: 0.5 }} variant="caption">
                //             {child.name}
                //           </Typography>
                //         </Box>
                //         <Box
                //           sx={{
                //             px: 1
                //           }}>
                //           <Typography sx={{ fontWeight: 600 }} variant="h5">
                //             1.1333
                //           </Typography>

                //         </Box>
                //       </Grid>
                //       <Grid item xs={10}>
                //         <Box
                //           sx={{
                //             px: 1,
                //             textAlign: 'right'
                //           }}>
                //           <Typography variant="caption">

                //             +0.07% - 0.0008
                //           </Typography>

                //         </Box>
                //         <Box
                //           sx={{
                //             px: 1,
                //             py: 0,
                //             textAlign: 'right',
                //             lineHeight: '0.9'
                //           }}>
                //           <Typography sx={{ lineHeight: '0.9' }} color="gray" variant="caption">
                //             H 1.1335
                //           </Typography>
                //           <br />
                //           <Typography sx={{ lineHeight: '0.9' }} color="gray" variant="caption">
                //             L 1.1321
                //           </Typography>

                //         </Box>

                //       </Grid>
                //     </Grid>

                //     <div style={styles.container}>
                //       {/* <Sparkline data={[
                //         41325.58695675088,
                //         41795.35404441451,
                //         41346.75755547179,
                //         41698.06528069751,
                //         41853.7126324314,
                //         41656.505042558805,
                //         41890.545239659165,
                //         41808.46520197215,
                //         42105.84704855687,
                //         42306.74186075323,
                //         42311.23662781285,
                //         42364.41551602892,
                //         42130.68177026306,
                //         42135.106775957975,
                //         42243.851002932126,
                //         41945.14058742656,
                //         42049.451722500395,
                //         41740.46854333929,
                //         41977.97404052251,
                //         41915.61971398387,
                //         41741.98689268442,
                //         41639.209139319355,
                //         41628.0329959911,
                //         43026.634910880945,
                //         42496.08715199643,
                //         42847.00401898649,
                //         43161.26720258187,
                //         42805.11267846471,
                //         42758.81667143197,
                //         42836.282931337686,
                //         42811.149099488095,
                //         42736.150332354366,
                //         42690.185587975626,
                //         42590.282253812686,
                //         42691.535020841184,
                //         42650.881859577625,
                //         42819.939667867315,
                //         42726.12283691961,
                //         42648.50740945896,
                //         42703.84164196805,
                //         42820.60036227033,
                //         42893.65433215027,
                //         43178.3020044094,
                //         43286.3461468275,
                //         44142.95617046611,
                //         43954.05064468909,
                //         43537.75551153419,
                //         43714.08929251948,
                //         43946.62531700104,
                //         43643.559182658464,
                //         43830.018518688914,
                //         44079.045729932375,
                //         43851.179214070566,
                //         43933.94798971295,
                //         43981.87732717414,
                //         43702.3823096264,
                //         43585.47818629988,
                //         43628.98833147167,
                //         43545.54886489405,
                //         43692.499996266684,
                //         43743.26864684281,
                //         43705.251773803444,
                //         43754.37515992663,
                //         43920.6078574499,
                //         43890.03403522622,
                //         43880.251837517484,
                //         43680.55986683123,
                //         43816.41969175357,
                //         43991.10705362516,
                //         44046.08436602141,
                //         42979.593359374114,
                //         43324.93335811952,
                //         42817.17658254182,
                //         42743.748516695814,
                //         42724.89581260096,
                //         42720.94616556062,
                //         42899.81519999951,
                //         42641.77574380057,
                //         42608.41001195019,
                //         42822.3410626846,
                //         42630.730370044825,
                //         42827.69538217487,
                //         42780.31638262786,
                //         42727.79226823351,
                //         42894.38226108194,
                //         42899.36778267711,
                //         42638.96747016387,
                //         42574.67989666804,
                //         42644.981834357044,
                //         42086.466454609246,
                //         42024.69661257974,
                //         42271.47151393987,
                //         42203.4285747024,
                //         43396.856151091495,
                //         43094.85541933739,
                //         43318.02949176713,
                //         42937.290331282704,
                //         43093.48998490977,
                //         43254.93326852252,
                //         43167.941146500394,
                //         43494.76826815796,
                //         43433.83319474636,
                //         43191.97077835075,
                //         43204.25614289687,
                //         43008.05726400687,
                //         43149.46797893759,
                //         43018.88169344696,
                //         43002.59260903966,
                //         43143.71368441771,
                //         43169.33853917334,
                //         43106.31841360756,
                //         43089.40095195211,
                //         43038.46408581668,
                //         43194.632756373794,
                //         43049.23746683092,
                //         42669.96652910112,
                //         43142.38806679312,
                //         43333.255381162264,
                //         43495.44513376632,
                //         43448.40684692052,
                //         43593.93677461069,
                //         43418.60863310491,
                //         43512.82658701286,
                //         43621.32296679522,
                //         43314.19235421247,
                //         43323.334689302115,
                //         43173.14113478345,
                //         43110.52062155796,
                //         43106.50936358114,
                //         42979.252744066725,
                //         43088.96743528128,
                //         43093.090288479245,
                //         43071.30042435913,
                //         43243.98300180644,
                //         43298.474670668285,
                //         43145.36827843491,
                //         43138.66543732607,
                //         43065.09759884792,
                //         43084.29328016314,
                //         43146.87895287532,
                //         43137.900230492865,
                //         43359.673918935296,
                //         43234.84803526138,
                //         43445.73263441537,
                //         43111.24443881516,
                //         43111.69338321902,
                //         42819.45590325531,
                //         43172.13965861911,
                //         43047.85859702999,
                //         43225.92579999611,
                //         43119.79214308028,
                //         42877.858416602336,
                //         42899.268988348034,
                //         42989.70122789917,
                //         42749.711818578595,
                //         42712.08236994989,
                //         42599.83212961661,
                //         42843.846816992016,
                //         42718.431064867356,
                //         42918.18830305879,
                //         42953.704362756995,
                //         42848.42337504775,
                //         42787.2565709712,
                //         42666.07903883221,
                //         42682.25091040553,
                //         42706.84109519157,
                //         42554.380859461504,
                //         42288.63596857892
                //       ]}
                //         color="gray"
                //         width="150"
                //       /> */}
                //     </div>
                //   </Box>
                // </Grid>
              )
            })}


          </Grid>
        </Grid>
      </Grid>
      <Box>
        <ForexHeatMap currencies={currencies} colorTheme={theme.palette.mode} autosize></ForexHeatMap>
      </Box>
    </Box>
  );
}
