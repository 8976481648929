import React from "react";
import { PriceHistory, PriceYesterday } from "../Component/Prototype/PriceHistory";



export function Prototype() {
  return (
      <div>
        <h2>Protoype</h2>
        <div className="flex flex-row space-x-5">
          <PriceHistory/>
          <PriceYesterday/>
        </div>
      </div>
  );
}
