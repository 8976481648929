import React, { ReactNode } from "react";
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { TopBar } from "./TopBar";
import Menu from "../Component/Menu/Menu";
import Submenu from "../Component/Submenu/Submenu";
import { useTheme } from '@mui/material/styles';
import theme from '../theme';

type Props = { children: ReactNode }

const cryptoChilds = [
    { name: "All Assets", grandChilds: [] },
    {
        name: "Market Cap",
        grandChilds: [
            "Mega Cap",
            "Large Cap",
            "Mid Cap",
            "Small Cap",
            "Micro Cap",
            "Nano Cap"
        ]
    },
    {
        name: "Sectors",
        grandChilds: [
            "Currencies",
            "Smart contract platforms",
            "Currencies",
            "Stable coins",
            "Exchange tokens",
            "Web3",
            "Decentrailized exchanges",
            "Privacy"]
    },
    {
        name: "Fundamentals",
        grandChilds: [
            "Active addresses",
            "Fee’s",
            "Transaction Volume"
        ]
    },
    {
        name: "Ecosystems",
        grandChilds: [
            "Polkadot",
            "Cosmos",
            "BSC",
            "Solana",
            "Polygon",
            "Yearn"
        ]
    },
    {
        name: "Volume",
        grandChilds: [
            "Top assets",
            "Defi",
            "Smart contracts platforms",
            "Currencies",
            "Stable coins",
            "Exchange tokens",
            "Web3",
            "Privacy coins",
            "Interoperability",
            "Decentrailized exchanges",
            "Lending",
            "Derivatives",
            "Asset management",
            "Data management",
            "File storage",
            "Gaming"
        ]
    },
    { name: "Top Treading", grandChilds: [] },
    { name: "Recently Added", grandChilds: [] },
    { name: "Biggest Gainers", grandChilds: [] },
    { name: "Biggest Losers", grandChilds: [] },
];
const stocksChilds = [
    {
        name: "Market Cap",
        grandChilds: [
            "Mega Cap",
            "Large Cap",
            "Mid Cap",
            "Small Cap",
            "Micro Cap",
            "Nano Cap"
        ]
    },
    {
        name: "Sector",
        grandChilds: [
            {
                name: "Energy",
                greatGrandchilds: [
                    "Energy Equipment & Services Industry",
                    "Oil, Gas & Consumable Fuels Industry"
                ]
            },
            {
                name: "Materials",
                greatGrandchilds: [
                    "Chemicals Industry",
                    "Construction Materials Industry",
                    "Containers & Packaging Industry",
                    "Metals & Mining Industry",
                    "Paper & Forest Products Industry"
                ]
            },
            {
                name: "Industrials",
                greatGrandchilds: [
                    "Aerospace & Defense Industry",
                    "Air Freight & Logistics Industry",
                    "Airlines Industry",
                    "Building Products Industry",
                    "Commercial Services & Supplies Industry",
                    "Construction & Engineering Industry",
                    "Electrical Equipment Industry",
                    "Industrial Conglomerates Industry",
                    "Machinery Industry",
                    "Marine Industry",
                    "Professional Services Industry",
                    "Road & Rail Industry",
                    "Trading Companies & Distributors Industry",
                    "Transportation Infrastructure Industry"
                ]
            },
            {
                name: "Utilities",
                greatGrandchilds: [
                    "Electric Utilities Industry",
                    "Gas Utilities Industry",
                    "Independent Power and Renewable Electricity Producers Industry",
                    "Multi-Utilities Industry",
                    "Water Utilities Industry"
                ]
            },
            {
                name: "Healthcare",
                greatGrandchilds: [
                    "Biotechnology Industry",
                    "Health Care Equipment & Supplies Industry",
                    "Health Care Providers & Services Industry",
                    "Health Care Technology Industry",
                    "Life Sciences Tools & Services Industry",
                    "Pharmaceuticals Industry"
                ]
            },
            {
                name: "Financials",
                greatGrandchilds: [
                    "Capital Markets Industry",
                    "Consumer Finance Industry",
                    "Diversified Financial Services Industry",
                    "Insurance Industry",
                    "Mortgage Real Estate Investment Trusts (REITs) Industry",
                    "Thrifts & Mortgage Finance Industry"
                ]
            },
            {
                name: "Consumers Discretionary",
                greatGrandchilds: [
                    "Automobile Components Industry",
                    "Automobiles Industry",
                    "Distributors Industry",
                    "Diversified Consumer Services Industry",
                    "Hotels, Restaurants & Leisure Industry",
                    "Household Durables Industry",
                    "Leisure Products Industry",
                    "Multiline Retail Industry",
                    "Specialty Retail Industry",
                    "Textile, Apparel & Luxury Goods Industry",
                    "Internet & Direct Marketing"
                ]
            },
            {
                name: "Consumer Staples",
                greatGrandchilds: [
                    "Beverages Industry",
                    "Food & Staples Retailing Industry",
                    "Food Products Industry",
                    "Household Products Industry",
                    "Personal Products Industry",
                    "Tobacco Industry"
                ]
            },
            {
                name: "Information Technology",
                greatGrandchilds: [
                    "Communications Equipment Industry",
                    "Electronic Equipment, Instruments & Components Industry",
                    "IT Services Industry",
                    "Semiconductors & Semiconductor Equipment Industry",
                    "Software Industry",
                    "Technology Hardware, Storage & Peripherals Industry"
                ]
            },
            {
                name: "Communication Services",
                greatGrandchilds: [
                    "Diversified Telecommunication Services",
                    "Wireless Telecommunication Services",
                    "Entertainment",
                    "Media",
                    "Interactive Media & Services"
                ]
            },
            {
                name: "Real Estate",
                greatGrandchilds: [
                    "Equity Real Estate Investment Trusts",
                    "Real Estate Management & Development"
                ]
            },
        ]
    },
    { name: "Biggest Gainers", grandChilds: [] },
    { name: "Biggest Losers", grandChilds: [] },
    { name: "52 Week High", grandChilds: [] },
    { name: "52 Week Low", grandChilds: [] },
    { name: "Recently Added", grandChilds: [] },
    { name: "Common Stocks", grandChilds: [] },
    { name: "International Stocks", grandChilds: [] },
    { name: "Dividend Stocks", grandChilds: [] },
    { name: "OTC Stock", grandChilds: [] },
    // "By country", "", "Sector", "OTC"
];
const nftsChilds = ["All collections", "Biggest Gainers", "Biggest Losers", "Sales Volume"];
const etfsChilds = [
    "Country",
    "Bond",
    "Sector",
    "Industry",
    "Broad Equity",
    "Large Cap",
    "Mid Cap Equity",
    "Small Cap Equity",
    "Leveraged",
    "Inverse",
    "Real Estate",
    "Fixed-Income",
    "Commodities",
    "Currency",
    "Precious Metals",
    "Money Market",
    "Emerging Markets",
    "International",
    "Energy",
    "Technology",
    "Alternative"
];
const bondsChilds = [
    {
        name: "U.S Treasury",
        grandChilds: [
            "Treasury Bonds",
            "Treasury Notes",
            "Treasury Bills"
        ]
    },
    {
        name: "U.S Savings", grandChilds: []
    },
    {
        name: "Mortgage-Backend", grandChilds: []
    },
    {
        name: "Corporate", grandChilds: []
    },
    {
        name: "TIPS and STRIPS", grandChilds: []
    },
    {
        name: "Agency", grandChilds: []
    },
    {
        name: "Municipal", grandChilds: []
    },
    {
        name: "International and Emerging Markets", grandChilds: []
    }
];
const forexChilds = [
    { name: "All Pairs", grandChilds: [] },
    {
        name: "Major",
        grandChilds: [
            "EUR/USD",
            "USD/JPY",
            "GBP/USD",
            "AUD/USD",
            "USD/CAD",
            "USD/CHF",
            "NZD/USD"
        ]
    },
    {
        name: "Currency ETF’s", grandChilds: []
    }
];
const commoditiesChildsChilds = [
    {
        name: "Precious Metals",
        grandChilds: [
            "Stocks",
            "ETF’s"
        ]
    },
    {
        name: "Currency ETF", grandChilds: []
    },
    {
        name: "Oil and Gas",
        grandChilds: [
            "Stocks",
            "ETF’s"
        ]
    },
    {
        name: "All Commodity Equities", grandChilds: []
    }
];
const alternativesChilds = [
    "Art", "Wine", "Venture Capital", "Private Equity", "Baseball Cards", "Rare Coins", "Autographs", "Stamps"
];

const Search = styled('div')(({ theme }) => ({
    display: 'inline-block',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export function Layout({ children }: Props) {

    const theme = useTheme();

    return (
        <Box sx={{
            height: '100%',
            backgroundColor: theme.palette.bg.main,
        }}>
            <TopBar />
            <Menu />
            <Box
                sx={{
                    position: 'relative',
                    backgroundColor: theme.palette.bg.main,
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 0.5,
                    mx: 1,
                    mb: 1
                }}
            >
                <Submenu label="Crypto" redirect="crypto">
                    <div className="technologies-list">
                        {cryptoChilds.map((child, index) => {
                            return (
                                <>
                                    {
                                        child.grandChilds.length > 0 ?
                                            (<Submenu label={child.name}>
                                                {child.grandChilds.map((gc, index) => {
                                                    return (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'block',
                                                                m: 2,
                                                            }}
                                                        >
                                                            {gc}
                                                        </Box>
                                                    )
                                                })
                                                }
                                            </Submenu>)
                                            : <label key={index}>{child.name}</label>
                                    }
                                </>
                            )
                        })}
                    </div>
                </Submenu>
                <Submenu label="NFT's">
                    <div className="technologies-list">
                        {nftsChilds.map((child, index) => {
                            return (
                                <label key={index}>{child}</label>
                            )
                        })}
                    </div>
                </Submenu>
                <Submenu label="Stocks" redirect="stocks">
                    <div className="technologies-list">
                        {stocksChilds.map((child, index) => {
                            return (
                                <>
                                    {
                                        child.grandChilds.length > 0 ?
                                            (<Submenu label={child.name}>
                                                {child.grandChilds.map((gc: any, index) => {
                                                    return (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'block',
                                                                m: 2,
                                                            }}
                                                        >
                                                            {gc.name}
                                                        </Box>
                                                    )
                                                })
                                                }
                                            </Submenu>)
                                            : <label key={index}>{child.name}</label>
                                    }
                                </>
                            )
                        })}
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </div>
                </Submenu>
                <Submenu label="ETF's">
                    <div className="technologies-list">
                        {etfsChilds.map((child, index) => {
                            return (
                                <label key={index}>{child}</label>
                            )
                        })}
                    </div>
                </Submenu>
                <Submenu label="Mutual Funds">
                </Submenu>
                <Submenu label="Bonds">
                    <div className="technologies-list">
                        {bondsChilds.map((child, index) => {
                            return (
                                <>
                                    {
                                        child.grandChilds.length > 0 ?
                                            (<Submenu label={child.name}>
                                                {child.grandChilds.map((gc, index) => {
                                                    return (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'block',
                                                                m: 2,
                                                            }}
                                                        >
                                                            {gc}
                                                        </Box>
                                                    )
                                                })
                                                }
                                            </Submenu>)
                                            : <label key={index}>{child.name}</label>
                                    }
                                </>
                            )
                        })}
                    </div>
                </Submenu>
                <Submenu label="Forex" redirect="forex">
                    <div className="technologies-list">
                        {forexChilds.map((child, index) => {
                            return (
                                <>
                                    {
                                        child.grandChilds.length > 0 ?
                                            (<Submenu label={child.name}>
                                                {child.grandChilds.map((gc, index) => {
                                                    return (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'block',
                                                                m: 2,
                                                            }}
                                                        >
                                                            {gc}
                                                        </Box>
                                                    )
                                                })
                                                }
                                            </Submenu>)
                                            : <label key={index}>{child.name}</label>
                                    }
                                </>
                            )
                        })}
                    </div>
                </Submenu>
                {/* <Submenu label="Metals">
            <div className="technologies-list">
              {cChilds.map((child, index) => {
                return (
                  <label key={index}>{child}</label>
                )
              })}
            </div>
          </Submenu> */}
                <Submenu label="Commodities">
                    <div className="technologies-list">
                        {commoditiesChildsChilds.map((child, index) => {
                            return (
                                <>
                                    {
                                        child.grandChilds.length > 0 ?
                                            (<Submenu label={child.name}>
                                                {child.grandChilds.map((gc, index) => {
                                                    return (
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'block',
                                                                m: 2,
                                                            }}
                                                        >
                                                            {gc}
                                                        </Box>
                                                    )
                                                })
                                                }
                                            </Submenu>)
                                            : <label key={index}>{child.name}</label>
                                    }
                                </>
                            )
                        })}
                    </div>
                </Submenu>
                <Submenu label="Alternatives">
                    <div className="technologies-list">
                        {alternativesChilds.map((child, index) => {
                            return (
                                <label key={index}>{child}</label>
                            )
                        })}
                    </div>
                </Submenu>
                <Submenu label="Economic Data" redirect="economic-data">
                </Submenu>
            </Box>

            <hr />
            <Box
                sx={{
                    backgroundColor: theme.palette.bg.light,
                    color: theme.palette.primary.contrastText
                }}>
                {children}
            </Box>
        </Box>
    );
}
