import { FormDialog } from "./AuthForm";
import { useAuth } from "./useAuth";

export const withAuth = (Component: any) => {
  return (props: any) => {
    const { isAuthenticated } = useAuth();

    return (
      <>
        {!isAuthenticated && <FormDialog open={true} />}
        <Component {...props} />
      </>
    );
  };
};
