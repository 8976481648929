import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Grid, Box, Button } from "@mui/material";
import BugReportIcon from '@mui/icons-material/BugReport';
import InfoIcon from '@mui/icons-material/Info';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import RouteIcon from '@mui/icons-material/Route';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import theme from '../theme';

const Item = styled(Box)(({ theme }) => ({
  textAlign: 'center'
}));

export function Support() {
  const theme = useTheme();
  return (
    <div>
      <Box sx={{ p: 8, margin: 'auto' }}>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              disabled
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}></Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              component={RouterLink}
              to="/report-issue"
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Item>
                <Grid container>
                  <Box sx={{ width: 1 }}>
                    <BugReportIcon color="primary" sx={{ fontSize: 60 }} />
                  </Box>
                  <Box sx={{ width: 1, mt: 1 }}>
                    <Typography variant="h5">Report issue</Typography>
                  </Box>
                </Grid>
              </Item>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}> Coming Soon</Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <InfoIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">About Rarechain API</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}> Coming Soon</Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <LiveHelpIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">FAQ</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}> Coming Soon</Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <RouteIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">Rarechain Development Roadmap</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}> Coming Soon</Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <DeveloperBoardIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">Continuos Improvement Board</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}> Coming Soon</Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <AttachMoneyIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">API Fee's</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={16}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                my: 0.5
              }}> Coming Soon</Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                color: theme.palette.neutral.contrastText,
                padding: theme.spacing(3)
              }}>
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <DashboardIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">Job Bounty Board</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Box >
    </div >
  );
}
