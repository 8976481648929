import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { EconomicCalendar } from "react-ts-tradingview-widgets";

export function EconomicData() {
  const theme = useTheme();

  return (
    <Box>
      <Grid
        sx={{ mt: 4 }}>
        <Box height="600px" sx={{ px: 2 }}>
          <EconomicCalendar colorTheme={theme.palette.mode} height={500} width="100%"></EconomicCalendar>
        </Box>
      </Grid>
    </Box>
  );
}
