import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { Button, Container, Input } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useApi } from "../../apis";

const assets = [
  { value: "Bitcoin(BTC)", label: "Bitcoin(BTC)" },
  { value: "Ethereum(ETH)", label: "Ethereum(ETH)" },
  { value: "Graph(GRT)", label: "Graph(GRT)" },
  { value: "Dogecoin (DOGE)", label: "Dogecoin (DOGE)" },
  { value: "Avalanche(AVAX)", label: "Avalanche(AVAX)" },
  { value: "Binance coin(BNB)", label: "Binance coin(BNB)" },
  { value: "Aave(AAVE)", label: "Aave(AAVE)" },
  { value: "Decentraland(MANA)", label: "Decentraland(MANA)" },
  { value: "Uniswap(UNI)", label: "Uniswap(UNI)" },
  { value: "Chainlink(LINK)", label: "Chainlink(LINK)" },
  { value: "zCash(ZEC)", label: "zCash(ZEC)" },
  { value: "The Sandbox(SAND)", label: "The Sandbox(SAND)" },
  { value: "SOL  (SOL)", label: "SOL  (SOL)" },
  { value: "MATIC (MATIC)", label: "MATIC (MATIC)" },
  { value: "Filecoin(FIL)", label: "Filecoin(FIL)" },
  { value: "Lido DAO(LDO)", label: "Lido DAO(LDO)" },
  { value: "1inch(1INCH)", label: "1inch(1INCH)" },
  { value: "Basic Attention(BAT)", label: "Basic Attention(BAT)" },
  { value: "Curve(CRV)", label: "Curve(CRV)" },
];

export interface TPredictionForm {
  asset: string;
  timeframe: string;
  prediction: string;
}

const defaultValues = {
  asset: "",
  timeframe: "",
  prediction: "",
};

export function PredictionForm() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TPredictionForm>({
    defaultValues,
  });

  const { savePrediction } = useApi();

  const onSubmit = (values: TPredictionForm) => {
    return savePrediction(values).then(() => {
      toast.success("Prediction saved successfully");
      reset();
    });
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        minHeight: 326,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          border: "1px solid",
          paddingX: 10,
          paddingY: 5,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <Box>
          <FormControl fullWidth error={!!errors?.asset}>
            <InputLabel id="demo-simple-select-label">Asset</InputLabel>
            <Controller
              name="asset"
              control={control}
              rules={{ required: "This field is required!" }}
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Asset"
                  {...field}
                >
                  {assets.map(({ value, label }) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText error={!!errors?.asset}>
              {errors?.asset?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box marginTop={4}>
          <FormControl variant="outlined" fullWidth error={!!errors?.timeframe}>
            <InputLabel htmlFor="timeframe">Timeframe(Hours)</InputLabel>
            <Controller
              name="timeframe"
              control={control}
              rules={{
                required: "This field is required!",
                min: { value: 1, message: "Min value can be 1" },
                max: { value: 2160, message: "Max value can be 2160" },
              }}
              render={({ field }) => (
                <Input
                  id="timeframe"
                  aria-describedby="my-helper-text"
                  {...field}
                />
              )}
            />
            <FormHelperText error={!!errors?.timeframe}>
              {errors?.timeframe?.message}
            </FormHelperText>
            <FormHelperText>
              Timeframe should be between 1 to 2160 hours
            </FormHelperText>
          </FormControl>
        </Box>
        <Box marginTop={4}>
          <FormControl fullWidth error={!!errors?.prediction}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Prediction
            </FormLabel>
            <Controller
              name="prediction"
              control={control}
              rules={{ required: "This field is required!" }}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  // name="row-radio-buttons-group"
                  {...field}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Up" />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Down"
                  />
                </RadioGroup>
              )}
            />
            <FormHelperText error={!!errors?.prediction}>
              {errors?.prediction?.message}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box marginTop={4}>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
            endIcon={<OnlinePredictionIcon color="primary" />}
            sx={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            Predict
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
