import * as React from 'react';
import * as io from "socket.io-client";
import { styled } from '@mui/material/styles';
import { Grid, Box, Button } from "@mui/material";
import Market from "../Component/CurrencyMarket/Market";
import { useTheme } from '@mui/material/styles';
import theme from '../theme';
import { currencyAbbreviations, currencySymbol as symbols } from '../currencies.js';
import { useEffect } from "react";

// import TVChartContainer from '../Component/TVChartContainer/TVChartContainer'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import socketIOClient from "socket.io-client";

// const ENDPOINT = "http://127.0.0.1:3000";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(6),
  textAlign: 'center',
  backgroundColor: theme.palette.neutral.light,
  borderColor: theme.palette.neutral.light
}));

const getCurrentCryptoCurrency = (msg: string) => {
  return { type: 'GET_CURRENT_CC_STATE', msg };
};

// prepare data for CurrentQuote
function getCryptoLongName(): string {
  return currencyAbbreviations.fromCurrencies["BTC"];
}

const getCryptoShortName = () => "BTC";

// const getCurrentPrice = (currencyPair: string | number) => {
//   return current[currencyPair] && current[currencyPair].price
//     ? formatPrice(current[currencyPair].price)
//     : undefined;
// };

const getPriceChange = (currencyPair: any) => {
  const priceChange = 0.1;
  return priceChange !== undefined
    ? Math.abs(priceChange).toFixed(3) + '%'
    : undefined;
};

const getPriceDir = (currencyPair: any) => {
  const priceChange = 0.1;
  if (priceChange > 0) {
    return 'up';
  }
  if (priceChange < 0) {
    return 'down';
  }
  return 'no-change';
};

const divStyle = {
  height: '270px'
};

const getSymbol = (currencyPair: string, fromOrTo: string) => {
  if (symbols && fromOrTo === 'toCur') {
    return symbols["USD"];
  }
  if (symbols && fromOrTo === 'fromCur') {
    return symbols["USD"];
  }
  return '';
};

export function Home() {
  const theme = useTheme();
  const [responseBtcTicker, setResponseBtcTicker] = React.useState("");
  const [responseEthTicker, setResponseEthTicker] = React.useState("");

  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   socket.on("msgBTCTicker", data => {
  //     if (data.TYPE !== '5')
  //       return;
  //     setResponseBtcTicker(data);
  //   });

  //   socket.on("msgETHTicker", data => {
  //     if (data.TYPE !== '5')
  //       return;
  //     setResponseEthTicker(data);
  //   });

  // }, []);


  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "https://s3.tradingview.com/tv.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);


  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://widget.nomics.com/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div>
      <Box sx={{ px: 4, margin: 'auto', py: 8 }}>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            {/* <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(6)
              }}>
              <Grid container spacing={2}> */}
            {/* <Grid item xs={6} md={8}>
                  <Box>
                    {getCryptoShortName()}
                    {getCryptoLongName()}
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>

                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>
                    {getSymbol("USD", 'toCur')}
                  </Box>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Box>
                    {getPriceDir("BTC")} {getPriceChange("BTC")}
                  </Box>
                </Grid> */}

            {/* <Market currencyTicker={responseBtcTicker} /> */}
            {/* <TVChartContainer /> */}
            {/* </Grid>
            </Button> */}
            <Box height="270px">
              <AdvancedRealTimeChart symbol="BTCUSDT" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
            </Box>

          </Grid>
          <Grid item xs={4}>
            {/* <Item>Cardano</Item> */}
            <AdvancedRealTimeChart symbol="BITKUB:ADATHB" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
          </Grid>
          <Grid item xs={4}>
            {/* <Item>USD/CAD forex pair</Item> */}
            <AdvancedRealTimeChart symbol="FX:USDCAD" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
          </Grid>
          <Grid item xs={4}>
            {/* <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(6)
              }}>
              <Grid container spacing={2}>
                <Market currencyTicker={responseEthTicker} />
              </Grid>
            </Button> */}
            <Box height="270px">
              <AdvancedRealTimeChart symbol="ETHUSDT" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
            </Box>
          </Grid>
          <Grid item xs={4}>
            {/* <Item>NASDAQ - QQQ</Item> */}
            <AdvancedRealTimeChart symbol="NASDAQ:QQQ" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
          </Grid>
          <Grid item xs={4}>
            {/* <Item>Gold</Item> */}
            <AdvancedRealTimeChart symbol="TVC:GOLD" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
          </Grid>
          <Grid item xs={4}>
            <div className="nomics-ticker-widget" data-name="QAO" data-base="QAO" data-quote="USD"></div>
          </Grid>
          <Grid item xs={4}>
            {/* <Item>Down Jones Index</Item> */}
            <AdvancedRealTimeChart symbol="PEPPERSTONE:US30" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
          </Grid>
          <Grid item xs={4}>
            {/* <Item>Crude Oil</Item> */}
            <AdvancedRealTimeChart symbol="CURRENCYCOM:OIL_CRUDE" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
          </Grid>
        </Grid>
      </Box>
    </div >
  );
}

