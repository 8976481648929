import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const apiUrl = process.env.REACT_APP_API_URL;

export interface TSavePrediction {
  prediction: string,
  asset: string,
  timeframe: string
}

export const useApi = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const savePrediction = async ({
    prediction,
    asset,
    timeframe
  }: TSavePrediction) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("asset", asset);
    urlencoded.append("prediction", prediction);
    urlencoded.append("timeframe", timeframe);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    fetch(`${apiUrl}/predictions/save`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return {
    savePrediction
  }

}