import React, { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { KeyValueTopBarPair } from "./KeyValueTopBarPair";

export function TopBar() {
    const theme = useTheme();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState({
        "cryptos": "",
        "exchanges": "",
        "stocks": "",
        "marketCap": "",
        "hVol": "",
        "dominance": "",
        "ethgas": "",
    });

    useEffect(() => {
        setItems({
            "cryptos": "18,470",
            "exchanges": "475",
            "stocks": "",
            "marketCap": "1,999,639,486,010",
            "hVol": "75,741,198,938",
            "dominance": "BTC: 42.1% ETH: 18.8%",
            "ethgas": "18 Gwei",
        });
    }, [])

    return (
        <Box className={'flex justify-between'} sx={{ pt: 0.5, backgroundColor: theme.palette.bg.main }}>
            <Box className={'flex'} >
                {/* {items && (
                    <>
                        <KeyValueTopBarPair label={'Cryptos'} value={`${items.cryptos}`} />
                        <KeyValueTopBarPair label={'Stocks'} value={items.stocks} />
                        <KeyValueTopBarPair label={'Exchanges'} value={items.exchanges} />
                        <KeyValueTopBarPair label={'Market Cap'} value={items.marketCap} />
                        <KeyValueTopBarPair label={'24h Vol'} value={items.hVol} />
                        <KeyValueTopBarPair label={'Dominance'} value={items.dominance} />
                        <KeyValueTopBarPair label={'ETH Gas'} value={items.ethgas} />
                    </>
                )
                } */}
            </Box>
            <div>
                <Avatar alt="QAO logo" src="/Icon.png" sx={{ width: 'auto', height: '60px' }} variant="square" />

            </div>
        </Box>
    );

}
