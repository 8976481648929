import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { computePercentChange, computeDateFormatted, computeDateDifference } from "../../utils";

type HighLowProps = {
  timePeriod: number;
  high: number;
  low: number;
}

function HighLowTableCell({timePeriod, high, low}:HighLowProps) {
  var timeFormat: string;
  if (timePeriod === 52) {
    timeFormat = "52 Week";
  } else {
    timeFormat = timePeriod + "d";
  }

  return (
    <TableRow>
      <TableCell align="left">{timeFormat} Low / {timeFormat} High</TableCell>
      <TableCell align="left">
        <List style={{padding: 0, float:'right'}}>
          <ListItem style={{padding: 0, fontSize: 12}} key={1}>${low} /</ListItem>
          <ListItem style={{padding: 0, fontSize: 12}} key={2}>${high}</ListItem>
        </List>
      </TableCell>
    </TableRow>
  );
}

type AllTimeProps = {
  date: Date;
  high?: number;
  low?: number;
  currentValue: number;
}

function AllTimeTableCell({date, high=-1, low=-1, currentValue}:AllTimeProps) {
  var mode: string = "High";
  var value: number = high;
  if (high === -1) {
    mode = "Low";
    value = low;
  }

  var percentChange: string = computePercentChange(value, currentValue);
  var dateFormatted: string = computeDateFormatted(date);
  var dateDifference: string = computeDateDifference(date);

  return (
    <TableRow>
      <TableCell align="left">
        <List style={{padding: 0}}>
          <ListItem style={{padding: 0}} key={1}>All Time {mode}</ListItem>
          <ListItem style={{padding: 0, fontSize: 12}} key={2}>{dateFormatted} ({dateDifference})</ListItem>
        </List>
      </TableCell>
      <TableCell align="right">
        <List style={{padding: 0, float: 'right'}}>
          <ListItem style={{padding: 0}} key={1}>${value}</ListItem>
          <ListItem style={{padding: 0, fontSize: 12}} key={2}>{percentChange}%</ListItem>
        </List>
      </TableCell>
    </TableRow>
  );
}

export function PriceHistory() {
  var assetName: string = "Bitcoin";
  return (
      <div className="">
        <TableContainer component={Paper}>
          <Table className="bg-slate-200" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{assetName} Price History</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <HighLowTableCell timePeriod={7} low={53569.76} high={59367.97}/>
              <HighLowTableCell timePeriod={30} low={53569.76} high={68789.63}/>
              <HighLowTableCell timePeriod={90} low={39787.61} high={68789.63}/>
              <HighLowTableCell timePeriod={52} low={17559.12} high={68789.63}/>
              <AllTimeTableCell date={new Date("2021-11-10")} high={68789.63} currentValue={53971.95}/>
              <AllTimeTableCell date={new Date("2013-07-05")} low={65.53} currentValue={53971.95}/>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  );
}



export function PriceYesterday() {
  var assetName: string = "Bitcoin";
  return (
      <div className="">
        <TableContainer component={Paper}>
          <Table className="bg-slate-200" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{assetName} Price Yesterday</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Yesterday's Low / High</TableCell>
                <TableCell align="left">
                  <List style={{padding: 0, float:'right'}}>
                    <ListItem style={{padding: 0, fontSize: 12}} key={1}>${53668.36} /</ListItem>
                    <ListItem style={{padding: 0, fontSize: 12}} key={2}>${55329.26}</ListItem>
                  </List>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Yesterday's Open / Close</TableCell>
                <TableCell align="left">
                  <List style={{padding: 0, float:'right'}}>
                    <ListItem style={{padding: 0, fontSize: 12}} key={1}>${53736.43} /</ListItem>
                    <ListItem style={{padding: 0, fontSize: 12}} key={2}>${54815.08}</ListItem>
                  </List>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Yesterday's Change</TableCell>
                <TableCell align="left">
                  <List style={{padding: 0, float:'right'}}>
                    <ListItem style={{padding: 0, fontSize: 12}} key={1}>{computePercentChange(53736.43, 54815.08)}%</ListItem>
                  </List>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Yesterday's Volume</TableCell>
                <TableCell align="left">
                  <List style={{padding: 0, float:'right'}}>
                    <ListItem style={{padding: 0, fontSize: 12}} key={1}>${30560857714.07}</ListItem>
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  );
}

type PriceHistoryData = {
  tokenName: string;
  ph7dl: number;
  ph7dh: number;
  ph_30dl: number;
  ph_30dh: number;
  ph_90dl: number;
  ph_90dh: number;
  ph_52wl: number;
  ph_52wh: number;
  ph_ath: number;
  ph_ath_date: number;
  ph_atl: number;
  ph_atl_date: number;
  ph_roi: number;
}

type PriceYesterdayData = {
  tokenName: string;
  py_low: number;
  py_high: number;
  py_open: number;
  py_close: number;
  py_change: number;
  py_volume: number;
}