import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import "./Submenu.css";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function Submenu({ children, onApply, label, redirect }: any) {
  const theme = useTheme();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [isCurrentUrl, setIsCurrentUrl] = useState(false);
  const dropdownRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLInputElement>(null);

  useEffect(() => {

    const handleClickOutside = (event: any) => {
      const isDropdownClick =
        dropdownRef.current && dropdownRef.current.contains(event.target);
      const isButtonClick =
        buttonRef.current && buttonRef.current.contains(event.target);

      if (isDropdownClick || isButtonClick) {
        // If the ref is not defined or the user clicked on the menu, we don’t do anything.
        return;
      }
      // Otherwise we close the menu.
      setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside); // handle desktops
    document.addEventListener("touchstart", handleClickOutside); // handle touch devices

    // Event cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // handle desktops
      document.removeEventListener("touchstart", handleClickOutside); // handle touch devices
    };
  }, [dropdownRef, buttonRef]);

  function displaySubmenu() {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className="filter">
        {
          redirect ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={displaySubmenu}
              className="filter__button"
              component={RouterLink}
              to={`/${redirect}`}
              sx={{
                fontWeight: 'bold',
              }}
              style={location.pathname.substring(1) == label.toLowerCase().replace(/\s/g, "-") ? { backgroundColor: '#3B4F8F' } : {}}
              endIcon={isOpen ? (<ArrowDropUpIcon fontSize='medium' />) : <ArrowDropDownIcon fontSize='medium' />}
            >
              {/* ffecd1 */}
              {/* ffd60a */}
              {/* d9d9d9 */}
              {label}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={displaySubmenu}
              className="filter__button"
              sx={{
                fontWeight: 'bold',
              }}
              style={location.pathname.substring(1) == (label.toLowerCase()).replace(/\s/g, "-") ? { backgroundColor: '#3B4F8F' } : {}}
              endIcon={isOpen ? (<ArrowDropUpIcon fontSize='medium' />) : <ArrowDropDownIcon fontSize='medium' />}
            >
              {label}
            </Button>
          )
        }

        <div>
          {isOpen && (
            <Box sx={{ backgroundColor: theme.palette.bg.main }} ref={dropdownRef} className="filter__dropdown">{children}</Box>
          )}
        </div>
      </div >

    </>
  );
};