import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "./AuthProvider";

interface TFormDialog {
  open: boolean;
}

export const FormDialog = ({ open }: TFormDialog) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { sendCode, verifyCode } = useContext(AuthContext);

  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = (data: any) => {
    const { email, code } = data;
    if (!emailSent) {
      console.log("Send OTP email", email);
      return sendCode(email)
        .then(() => {
          setEmailSent(true);
        })
        .catch(() => {
          alert("Some error occured");
        });
    } else {
      return verifyCode({ email, code });
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address, We will send you an code to your
            email address.
          </DialogContentText>
          <Controller
            name="email"
            rules={{
              required: "Email is required",
              validate: (emailToValidate) => {
                const emailRegexp =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                return emailRegexp.test(emailToValidate)
                  ? true
                  : "Enter a valid email address";
              },
            }}
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                error={!!errors["email"]}
                helperText={errors["email"]?.message}
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                autoComplete={"off"}
                {...field}
              />
            )}
          />

          {emailSent && (
            <Controller
              name="code"
              rules={{
                required: "Code is required",
                minLength: { value: 4, message: "Enter a valid code" },
                maxLength: { value: 4, message: "Enter a valid code" },
              }}
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  error={!!errors["code"]}
                  helperText={errors["code"]?.message}
                  margin="dense"
                  id="code"
                  label="Enter Code"
                  type="password"
                  fullWidth
                  variant="standard"
                  autoComplete={"off"}
                  {...field}
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>
            {emailSent ? "Verify Code" : " Send code"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
