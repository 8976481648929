import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

const items = [
  { name: "Home", isReady: true, redirect: "/" },
  { name: "Index Management", isReady: true, redirect: "/index-management" },
  { name: "Trade", isReady: false },
  { name: "Best Performing API Indexes", isReady: false },
  { name: "Back Test Index", isReady: false },
  { name: "RAIR", isReady: false },
  { name: "Watchlist", isReady: false },
  { name: "Support", isReady: true, redirect: "/support" },
];

export default function Menu() {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 1,
          mt: 0,
        }}
      >
        {items.map((i) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-end",
                mx: 0.5,
              }}
            >
              {!i.isReady ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="neutral"
                  fullWidth
                  sx={{
                    borderColor: theme.palette.neutral.light,
                    color: theme.palette.neutral.contrastText,
                    my: 0.5,
                  }}
                >
                  {" "}
                  Coming Soon
                </Button>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  color="neutral"
                  fullWidth
                  disabled
                  sx={{
                    borderColor: theme.palette.neutral.light,
                    my: 0.5,
                  }}
                ></Button>
              )}
              {i.redirect ? (
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  color="neutral"
                  component={RouterLink}
                  to={`${i.redirect}`}
                  sx={{
                    maxHeight: "30.75px",
                    backgroundColor: theme.palette.neutral.light,
                    fontWeight: "bold",
                    py: 0.5,
                    px: 1,
                  }}
                >
                  {i.name}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  color="neutral"
                  sx={{
                    maxHeight: "30.75px",
                    backgroundColor: theme.palette.neutral.light,
                    fontWeight: "bold",
                    py: 0.5,
                    px: 1,
                  }}
                >
                  {i.name}
                </Button>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
