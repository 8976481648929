import React from "react";
import { styled } from '@mui/material/styles';
import { Grid, Box, Link } from "@mui/material";
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ContactForm from "../Component/ContactForm/ContactForm";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.light,
  borderColor: theme.palette.primary.light
}));


export function ReportIssue() {

  return (
    <div>
      <Grid container justifyContent="center" sx={{ mt: 4, pb: 8 }}>
        <Card sx={{ maxWidth: 800 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Contact Us
            </Typography>

            <Typography gutterBottom variant="h3" component="div">
              We're here to help
            </Typography>
            <Typography variant="body2" >
              We provide email support Mondat through Friday. We do our best to respond to each request
              within 24-48 business hours.
              Report any issues to <Typography sx={{ fontWeight: 'bold' }}>Info@qao.io</Typography> or <Typography sx={{ fontWeight: 'bold' }}>"API report issues/bugs"</Typography> channel in Discord
            </Typography>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              Thank you
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              QAO Dev Team
            </Typography>
          </CardContent>
          <CardActions>
            <Link
              target="_blank"
              href="https://discord.com/invite/75jrNSE6SN"
              underline="none"
              rel="noreferrer"
            >
              Open discord
            </Link>
          </CardActions>
        </Card>
      </Grid>

    </div>
  );
}
