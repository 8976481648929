import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AllCoins from "../Component/Landing/AllCoins";
import { useTheme } from '@mui/material/styles';
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

export function Landing() {
  const theme = useTheme();

  return (
    <div>

      <Grid container columns={16}
        sx={{ mt: 7, mb: 4, px: 2 }}>
        {/* Left Pannel */}
        <Grid item xs={8}>
          <Box sx={{ height: 400 }}>A</Box>
        </Grid>
        {/* Right Pannel */}
        <Grid item xs={8} sx={{
          pr: 1
        }}>
          <AdvancedRealTimeChart symbol="BINANCE:BTCUSDT" theme={theme.palette.mode} autosize></AdvancedRealTimeChart>
        </Grid>
      </Grid>
      {/* <AllCoins /> */}
      <Box
        sx={{ px: 2 }}>
        <CryptoCurrencyMarket colorTheme={theme.palette.mode} width="100%"></CryptoCurrencyMarket>

      </Box>
    </div>);
}



/* 
<Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  GBP/USD
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  USD/JPY
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  USD/CAD
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  USD/CHF
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  AUD/USD
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  NZD/USD
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  EUR/GBP
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    height: 150,
                    color: 'white',
                    backgroundColor: 'primary.dark',
                  }}>
                  GBP/JPY
                </Box>
              </Grid>
*/