import AddIcon from "@mui/icons-material/Add";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ListIcon from "@mui/icons-material/List";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Box, Button, Grid } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(6),
  textAlign: "center",
  backgroundColor: theme.palette.primary.light,
  borderColor: theme.palette.primary.light,
}));

export function IndexManagement() {
  const theme = useTheme();
  return (
    <div>
      <Box sx={{ p: 8, margin: "auto" }}>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <RouterLink to={"/predictions"}>
              <Button
                variant="text"
                size="small"
                color="primary"
                fullWidth
                sx={{
                  boxShadow: 2,
                  backgroundColor: theme.palette.neutral.light,
                  borderColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                  padding: theme.spacing(3),
                }}
              >
                <Grid container>
                  <Box sx={{ width: 1 }}>
                    <HowToVoteIcon color="primary" sx={{ fontSize: 60 }} />
                  </Box>
                  <Box sx={{ width: 1, mt: 1 }}>
                    <Typography variant="h5">Predictions</Typography>
                  </Box>
                </Grid>
              </Button>
            </RouterLink>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                my: 0.5,
              }}
            >
              Coming Soon
            </Button>

            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(3),
              }}
            >
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <AddIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">Create</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                my: 0.5,
              }}
            >
              {" "}
              Coming Soon
            </Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(3),
              }}
            >
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <CalendarViewWeekIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">View & Manage</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                my: 0.5,
              }}
            >
              {" "}
              Coming Soon
            </Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(3),
              }}
            >
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <MenuBookIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">Personal Portfolio</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth
              sx={{
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                my: 0.5,
              }}
            >
              {" "}
              Coming Soon
            </Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              fullWidth
              sx={{
                boxShadow: 2,
                backgroundColor: theme.palette.neutral.light,
                borderColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(3),
              }}
            >
              <Grid container>
                <Box sx={{ width: 1 }}>
                  <LeaderboardIcon color="primary" sx={{ fontSize: 60 }} />
                </Box>
                <Box sx={{ width: 1, mt: 1 }}>
                  <Typography variant="h5">Leaderboards</Typography>
                </Box>
              </Grid>
            </Button>
          </Grid>

          <Grid item xs={3}>
            <RouterLink to={"/minted-indexes"}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                fullWidth
                sx={{
                  borderColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                  my: 0.5,
                }}
              >
                {" "}
                Coming Soon
              </Button>
              <Button
                variant="text"
                size="small"
                color="primary"
                fullWidth
                sx={{
                  boxShadow: 2,
                  backgroundColor: theme.palette.neutral.light,
                  borderColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                  padding: theme.spacing(3),
                }}
              >
                <Grid container>
                  <Box sx={{ width: 1 }}>
                    <ListIcon color="primary" sx={{ fontSize: 60 }} />
                  </Box>
                  <Box sx={{ width: 1, mt: 1 }}>
                    <Typography variant="h5">
                      View All Minted Indexes
                    </Typography>
                  </Box>
                </Grid>
              </Button>
            </RouterLink>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
